import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import {
  ProductAndVariantget,
  SubSubgetCategory,
  addorupdateSeo,
  cssfilter,
  getAllCategory,
  getAllSubCategory,
  getseo,
} from "../../services/api";
import * as yup from "yup";
import TextField from "../categories/TextField";
import Pagination from "../categories/categories/Pagination";

import "../../App.css";
import SeoPost from "./SeoPost";



function Seo() {
  const validate = yup.object({
    category: yup.string().required("Required"),
    product: yup.string().required("Required"),
    subCategory: yup.string(),
    subsubCategory: yup.string(),
    meta_title: yup.string().required("Required"),
    meta_description: yup.string().required("Required"),
    meta_keywords: yup.string().required("Required"),
    url_structure: yup.string().required("Required"),
    cononical_tag: yup.string().required("Required"),
  });

const [categories, setCategories] = useState([]);
const [products, setProducts] = useState([]);
const [allProducts, setAllProducts] = useState([]);
const [posts, setposts] = useState([]);
const [editingItem, setEditingItem] = useState(null);

useEffect(() => {

  const fetchData = async () => {
    try {
      // Fetch categories
      let res = await getAllCategory();
      console.log("Initial categories:", res);
      setCategories(res);
      const response = await getseo();

      setposts(response)
      
      // Fetch products
      const productsResponse = await ProductAndVariantget();
      console.log("Initial products response:", productsResponse);
      
      if (productsResponse && productsResponse.data) {
        const compressedProducts = [
          ...(productsResponse.data.products || []).map((item) => ({
            productid: item._id,
            productname: item.productname1,
            category: item.categoryid,
            subcategory: item.subcategory,
            subsubcategory: item.subsubcategory,
          })),
          ...(productsResponse.data.variant || []).map((item) => ({
            productid: item._id,
            productname: item.productname1,
            category: item.categoryid,
            subcategory: item.subcategory,
            subsubcategory: item.subsubcategory,
          })),
        ];
        console.log("Compressed products:", compressedProducts);
        setAllProducts(compressedProducts);
        setProducts(compressedProducts);
      } else {
        console.error("No data found for products");
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };
  fetchData();
}, []);


const [subCategories, setSubCategories] = useState([]);
const [subSubCategories, setSubSubCategories] = useState([]);

  const [subcategory, setSubcategory] = useState("");
  const [subsubcategory, setSubsubcategory] = useState("");
  const [category, setCategory] = useState("");
 
  const [searchedvalue, setsearchedvalue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(25);

  const Totalpages = Math.ceil(posts.length / postsPerPage);
  const pages = [...Array(Totalpages + 1).keys()].slice(1);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const fetchsubcategories = async () => {
      try {
        const response = await getAllSubCategory();
        if (response) {
          console.log("Category selected:", category);
          console.log("All subcategories:", response);
          
          // Filter subcategories based on selected category
          const filtersubcategory = response.filter(
            (item) => item.categoryname.toLowerCase() === category.toLowerCase()
          );
          console.log("Filtered subcategories:", filtersubcategory);
          setSubCategories(filtersubcategory);
          
          // Only reset if not editing
          if (!editingItem) {
            setSubcategory("");
            setSubsubcategory("");
          }
        }

        // Filter products based on selected category
        if (category) {
          const filterproducts = allProducts.filter(
            (item) => item.category.toLowerCase() === category.toLowerCase()
          );
          console.log("Filtered products by category:", filterproducts);
          setProducts(filterproducts);
        } else {
          setProducts(allProducts);
        }
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };
    if (category) {
      fetchsubcategories();
    }
  }, [category, allProducts, editingItem]);
  
  useEffect(() => {
    const fetchsubsubcategories = async () => {
      try {
        const response = await SubSubgetCategory();
        if (response) {
          console.log("Subcategory selected:", subcategory);
          console.log("All subsubcategories:", response);
          
          // Filter subsubcategories based on selected category and subcategory
          const filtersubsubcategory = response.filter(
            (item) => 
              item.categoryname.toLowerCase() === category.toLowerCase() && 
              item.subcategoryname.toLowerCase() === subcategory.toLowerCase()
          );
          console.log("Filtered subsubcategories:", filtersubsubcategory);
          setSubSubCategories(filtersubsubcategory);
          
          // Only reset if not editing
          if (!editingItem) {
            setSubsubcategory("");
          }
        }

        // Filter products based on selected category and subcategory
        if (subcategory) {
          const filterproducts = allProducts.filter(
            (item) => 
              item.category.toLowerCase() === category.toLowerCase() && 
              item.subcategory.toLowerCase() === subcategory.toLowerCase()
          );
          console.log("Filtered products by subcategory:", filterproducts);
          setProducts(filterproducts);
        }
      } catch (error) {
        console.error("Error fetching subsubcategories:", error);
      }
    };
    if (subcategory && category) {
      fetchsubsubcategories();
    }
  }, [subcategory, category, allProducts, editingItem]);
  
  useEffect(() => {
    // Filter products based on all selected categories
    if (allProducts.length > 0) {
      let filteredProducts = [...allProducts];
      
      if (category) {
        filteredProducts = filteredProducts.filter(
          (item) => item.category === category
        );
      }
      
      if (subcategory) {
        filteredProducts = filteredProducts.filter(
          (item) => item.subcategory === subcategory
        );
      }
      
      if (subsubcategory) {
        filteredProducts = filteredProducts.filter(
          (item) => item.subsubcategory === subsubcategory
        );
      }
      
      setProducts(filteredProducts);
    }
  }, [category, subcategory, subsubcategory, allProducts]);
  

  const handleEdit = async (item) => {
    try {
      setEditingItem(item);
      setCategory(item.category);
      
      // Fetch subcategories
      const subCatResponse = await getAllSubCategory();
      if (subCatResponse) {
        const filtersubcategory = subCatResponse.filter(
          (subcat) => subcat.categoryname.toLowerCase() === item.category.toLowerCase()
        );
        setSubCategories(filtersubcategory);
        setSubcategory(item.subCategory);
        
        // Fetch subsubcategories
        const subSubCatResponse = await SubSubgetCategory();
        if (subSubCatResponse) {
          const filtersubsubcategory = subSubCatResponse.filter(
            (subsubcat) => 
              subsubcat.categoryname.toLowerCase() === item.category.toLowerCase() && 
              subsubcat.subcategoryname.toLowerCase() === item.subCategory.toLowerCase()
          );
          setSubSubCategories(filtersubsubcategory);
          setSubsubcategory(item.subsubCategory);
        }
      }
    } catch (error) {
      console.error("Error fetching categories during edit:", error);
    }

    window.scrollTo(0, 0);
  };

  const resetForm = () => {
    setEditingItem(null);
    setCategory("");
    setSubcategory("");
    setSubsubcategory("");
    setSubCategories([]);
    setSubSubCategories([]);
    setProducts(allProducts);
  };

  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h4 className="page-title">SEO</h4>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-right"></ol>
                </div>
              </div>
            </div>

            <div className="row d-md-flex justify-content-md-end ">
              <div className="col-sm-6 d-md-flex justify-content-md-end"></div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title mb-4">Seo</h4>

                    <div className="table-responsive">
                      <Formik
                        initialValues={editingItem || {
                          category: "",
                          subCategory: "",
                          subsubCategory: "",
                          product: "",
                          meta_title: "",
                          meta_description: "",
                          meta_keywords: "",
                          url_structure: "",
                          cononical_tag: "",
                        }}
                        enableReinitialize={true}
                        validationSchema={validate}
                        onSubmit={async (values, action) => {
                          try {
                            let dat = await addorupdateSeo({
                              ...values,
                              _id: editingItem?._id
                            });

                            if (dat.status) {
                              alert(editingItem ? "Updated Successfully" : "Added Successfully");
                              setEditingItem(null);
                              window.location.reload();
                            } else {
                              alert("Something went wrong");
                            }
                          } catch (error) {
                            alert(error);
                          }
                          action.resetForm();
                        }}
                      >
                        {(formik) => {
                          
                          return (
                          <Form>
                            <div className="row mt-2 mx-0">
                              <div className="col mt-2">
                                <label>Category Name</label>
                                <Field
                                  as="select"
                                  className={`form-control shadow-none ${
                                    formik.touched.category &&
                                    formik.errors.category &&
                                    "is-invalid"
                                  }`}
                                  onChange={(e) => {
                                    setCategory(e.target.value);
                                    formik.setFieldValue("category", e.target.value);
                                  }}
                                  value={category}
                                  name="category"
                                >
                                  <option defaultValue="">
                                    Select Category
                                  </option>
                                  {categories &&
                                    categories.map((i, index) => {
                                      return (
                                        <option key={index} value={i.title}>
                                          {i.title}
                                        </option>
                                      );
                                    })}
                                </Field>
                                <ErrorMessage
                                  component="div"
                                  name="category"
                                  className="error"
                                />
                              </div>
                              <div className="col mt-2">
                                <label> Sub Category Name</label>
                                <Field
                                  as="select"
                                  className={`form-control shadow-none ${
                                    formik.touched.subCategory &&
                                    formik.errors.subCategory &&
                                    "is-invalid"
                                  }`}
                                  value={subcategory}
                                  name="subCategory"
                                  onChange={(e) => {
                                    console.log(e.target.value,"value")
                                    setSubcategory(e.target.value);
                                    formik.setFieldValue("subCategory", e.target.value);
                                  }}
                                >
                                  <option value="">Select Sub Category</option>
                                  {subCategories &&
                                    subCategories.map((i, index) => (
                                      <option
                                        key={index}
                                        value={i.subcategory}
                                      >
                                        {i.subcategory}
                                      </option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                  component="div"
                                  className="error"
                                  name="subCategory"
                                />
                              </div>

                              <div className="col mt-2">
                                <label> Sub Sub Category Name</label>
                                <Field
                                  as="select"
                                  className={`form-control shadow-none ${
                                    formik.touched.subsubCategory &&
                                    formik.errors.subsubCategory &&
                                    "is-invalid"
                                  }`}
                                  value={subsubcategory}
                                  onChange={(e) => {
                                    console.log(e.target.value,"value")
                                    setSubsubcategory(e.target.value);
                                    formik.setFieldValue(
                                      "subsubCategory",
                                      e.target.value
                                    );
                                  }}
                                  name="subsubCategory"
                                >
                                  <option value="">Select Sub Sub Category</option>
                                  {subSubCategories &&
                                    subSubCategories.map((i, index) => (
                                      <option 
                                        key={index}
                                        value={i.subsubcategory}
                                      >
                                        {i.subsubcategory}
                                      </option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                  component="div"
                                  name="subsubCategory"
                                  className="error"
                                />
                              </div>
                              <div className="col mt-2">
                                <label>Product Name</label>
                                <Field
                                  as="select"
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "product",
                                      e.target.value
                                    );
                                  }}
                                  value={formik.values.product}
                                  className={`form-control shadow-none ${
                                    formik.touched.product &&
                                    formik.errors.product &&
                                    "is-invalid"
                                  }`}
                                  name="product"
                                >
                                  <option defaultValue="">
                                    Select Product
                                  </option>
                                  {products &&
                                    products.map((product, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={product.productname}
                                        >
                                          {product.productname}
                                        </option>
                                      );
                                    })}
                                </Field>
                                <ErrorMessage
                                  component="div"
                                  name="product"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="row mt-2 mx-0">
                              <div className="col mt-2">
                                <TextField
                                  label="Meta Title"
                                  name="meta_title"
                                />
                              </div>
                              <div className="col mt-2">
                                <TextField
                                  label="Meta Description"
                                  name="meta_description"
                                />
                              </div>
                              <div className="col mt-2">
                                <TextField
                                  label="Meta Keywords"
                                  name="meta_keywords"
                                />
                              </div>
                              <div className="col mt-2">
                                <TextField
                                  label="URL Structure"
                                  name="url_structure"
                                />
                              </div>
                              <div className="col mt-2">
                                <TextField
                                  label="Canonical Tag"
                                  name="cononical_tag"
                                />
                              </div>
                            </div>

                            <br />
                            <input
                              type="Submit"
                              className="btn mt-2 rounded-3 w-20 btn-lg btn-outline-secondary btn-dark"
                              value={editingItem ? "Update" : "Submit"}
                            />
                            {editingItem && (
                              <button
                                type="button"
                                className="btn mt-2 mx-2 rounded-3 w-20 btn-lg btn-outline-secondary"
                                onClick={() => {
                                  resetForm();
                                  formik.resetForm();
                                }}
                              >
                                Cancel
                              </button>
                            )}
                          </Form>
                        )}}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title mb-4">SEO</h4>

                    <form
                      className="d-flex mb-2 "
                      style={{ width: "50%" }}
                      role="search"
                    >
                      <div className="btn-group">
                        <input
                          className="form-control  mx-2  btn-close"
                          type="search"
                          value={searchedvalue}
                          placeholder="Search category"
                          onChange={(e) => {
                            setsearchedvalue(e.target.value);
                          }}
                          aria-label="Search Product Name"
                        />
                        <button
                          type="button"
                          className="btn bg-transparent"
                          style={{ left: "-43px" }}
                          onClick={async () => {
                            let data = await getseo();
                            console.log(data,"data")
                            setposts(data.data);
                            setsearchedvalue("");
                          }}
                        >
                          <i
                            className="fa fa-times"
                            style={{ color: "white" }}
                          ></i>
                        </button>
                        <button
                          className="btn rounded btn-md btn-outline-secondary btn-dark"
                          type="submit"
                          onClick={async (e) => {
                            e.preventDefault();

                            if (searchedvalue) {
                              let dat = await cssfilter(searchedvalue);
                              setposts(dat.data);
                            } else {
                              let data = await getseo();
                              setposts(data.data);
                            }
                          }}
                        >
                          Search
                        </button>
                      </div>
                    </form>

                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col"> Sr No. </th>
                            <th scope="col"> category </th>
                            <th scope="col"> sub category </th>
                            <th scope="col">sub sub category</th>
                            <th scope="col">Product</th>
                            <th scope="col">Meta Title</th>
                            <th scope="col">Meta Description</th>
                            <th scope="col">Meta Keywords</th>
                            <th scope="col">URL Structure</th>
                            <th scope="col">Canonical Tag</th>
                          </tr>
                        </thead>
                        <tbody>
                          <SeoPost posts={currentPosts} onEdit={handleEdit} />
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      postsPerPage={postsPerPage}
                      totalPosts={Totalpages}
                      paginate={paginate}
                      currentPage={currentPage}
                      pageNumbers={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Seo;

import React from "react";
import {
  vendorupdated,
  
  vendorStatusUpdate,
  vendorBeforeDelete,
} from "../../services/api";

function Requestvendorposts({ posts }) {

  

  const confirm = async (i) => {
    let dat = await vendorupdated(i._id, { approved: true });
    if (dat.status) {
      alert("VENDOR REQUEST CONFIRMED");
      window.location.reload();
    } else {
      alert("Something went wrong");
      window.location.reload();
    }
  };

  const remove = async (i) => {
    let dat = await vendorBeforeDelete(i._id);
    if (dat.status) {
      alert("VENDOR REQUEST DELETED", dat.data);
      window.location.reload();
    } else {
      alert("Something went wrong");
      window.location.reload();
    }
  };

  const toggleStatus = async (i) => {
    // Toggle status between 'active' and 'pending'
    let newStatus = i.status === "active" ? "pending" : "active";
    let dat = await vendorStatusUpdate(i._id, { status: newStatus });

    if (dat.status) {
      alert(`VENDOR REQUEST STATUS CHANGED TO ${newStatus.toUpperCase()}`);
      window.location.reload();
    } else {
      alert("Something went wrong");
      window.location.reload();
    }
  };

  return (
    <>
      {posts &&
        posts.map((i, index) => {
          if (i.approved) {
            return null; // Skip approved requests
          }
          return (
            <tr key={i._id}>
              <td>
                <span>{index + 1}</span>
              </td>
              <td>
                <span>{i.name}</span>
              </td>
              <td>
                <span>{i.email}</span>
              </td>
              <td>
                <span>{i.mobileno}</span>
              </td>
              <td>
                <span>{i.pincode}</span>
              </td>
              <td>
                <span>{i.gst}</span>
              </td>
              <td>
                <span>{i.address}</span>
              </td>
              <td>
                <span>{new Date(i.createdAt).toLocaleDateString()}</span>{" "}
                {/* Add Date */}
              </td>
              <td>
                {i.status !== "pending" && (
                  <>
                    <button
                      className="btn btn-success m-2"
                      onClick={() => confirm(i)}
                    >
                      Confirm
                    </button>
                    <button
                      className="btn btn-danger m-2"
                      onClick={() => remove(i)}
                    >
                      Remove
                    </button>
                  </>
                )}
                <button
                  className={`btn m-2 ${
                    i.status === "active" ? "btn-primary" : "btn-warning"
                  }`}
                  onClick={() => toggleStatus(i)}
                >
                  {i.status === "active" ? "Hold" : "Pending"}
                </button>
              </td>
            </tr>
          );
        })}
    </>
  );
}

export default Requestvendorposts;

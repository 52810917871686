import { React, useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import TextField from "../TextField";
import * as yup from "yup";
import { getAllCategory, SubCategory, UploadFile } from "../../../services/api";
import JoditEditor from "jodit-react";
/* eslint-disable */
function AddSubCategoryform() {
  const editor = useRef(null);
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/webp"];
  const validate = yup.object({
    categoryid: yup.string().required("Required"),
    subcategory: yup.string().required("Required"),
    subcategoryimg: yup
      .mixed()
      .nullable()
      .required("!Required")
      .test(
        "FILE_FORMAT",
        "Uploaded file has unsupported format. Only PNG, JPG and WEBP files are allowed",
        (value) => !value || SUPPORTED_FORMATS.includes(value?.type)
      ),
    description: yup.string(),
    isFeatured: yup.boolean(),
    isTopProduct: yup.boolean()
  });
  const [state, setstate] = useState([]);
  useEffect(() => {
    async function data() {
      let dat = await getAllCategory();
      setstate(dat);
    }
    data();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card m-b-30">
            <div className="card-body">
              <h4 className="mt-0 header-title mb-4">Add Sub Category</h4>
              <div className="table-responsive">
                <Formik
                  initialValues={{
                    categoryid: "",
                    subcategory: "",
                    subcategoryimg: "",
                    description: "",
                    isFeatured: false,
                    isTopProduct: false
                  }}
                  onSubmit={async (values, actions) => {
                    try {
                      if (values.subcategoryimg) {
                        const data = new FormData();
                        data.append("name", values.subcategoryimg.name);
                        data.append("file", values.subcategoryimg);
                        let subimg = await UploadFile(data);
                        values.subcategoryimg = subimg.data;
                      }
                    } catch (error) {
                      alert(error);
                    }
                    try {
                      let response = await SubCategory(values);
                      if (response.status) {
                        alert("SUCCESSFULLY CREATED");
                        window.location.reload();
                      } else {
                        alert("something went wrong");
                      }
                    } catch (error) {
                      alert(error);
                    }
                    actions.resetForm();
                  }}
                  validationSchema={validate}
                >
                  {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                      <label>Category Name</label>
                      <Field
                        as="select"
                        className={`form-control shadow-none  ${
                          formik.touched.categoryid &&
                          formik.errors.categoryid &&
                          "is-invalid"
                        }`}
                        id="title"
                        name="categoryid"
                      >
                        <option defaultValue="">Select Category</option>
                        {state &&
                          state.map((i, index) => (
                            <option key={index} value={i._id}>
                              {i.title}
                            </option>
                          ))}
                      </Field>

                      <ErrorMessage
                        name="categoryid"
                        component="div"
                        className="error"
                      />

                      <br />
                      <TextField label="Sub Category" name="subcategory" />
                      <label>Sub Category Image</label>
                      <input
                        type="file"
                        accept="image/png, image/jpg, image/jpeg, image/webp"
                        onChange={(e) =>
                          formik.setFieldValue(
                            "subcategoryimg",
                            e.target.files[0]
                          )
                        }
                        className={`form-control shadow-none mb-2 ${
                          formik.touched.subcategoryimg &&
                          formik.errors.subcategoryimg &&
                          "is-invalid"
                        }`}
                        name="subcategoryimg"
                        placeholder="Image"
                      />
                      <ErrorMessage
                        name="subcategoryimg"
                        className="error"
                        component="div"
                      />

                      <br />

                      <label>Description</label>
                      <JoditEditor
                        ref={editor}
                        value={formik.values.description}
                        tabIndex={1}
                        onBlur={(newContent) => 
                          formik.setFieldValue("description", newContent)
                        }
                        onChange={(newContent) => 
                          formik.setFieldValue("description", newContent)
                        }
                      />
                      <ErrorMessage
                        name="description"
                        className="error"
                        component="div"
                      />
                      <br />

                      <div className="mb-3">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isFeatured"
                            checked={formik.values.isFeatured}
                            onChange={() => 
                              formik.setFieldValue("isFeatured", !formik.values.isFeatured)
                            }
                          />
                          <label className="form-check-label" htmlFor="isFeatured">
                            Featured Product
                          </label>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isTopProduct"
                            checked={formik.values.isTopProduct}
                            onChange={() => 
                              formik.setFieldValue("isTopProduct", !formik.values.isTopProduct)
                            }
                          />
                          <label className="form-check-label" htmlFor="isTopProduct">
                            Top Product
                          </label>
                        </div>
                      </div>

                      <input
                        type="submit"
                        className="btn mt-2 rounded-3 w-20  btn-lg btn-outline-secondary btn-dark"
                        value="Submit"
                      />
                    </Form>
                  )}
                </Formik>
              </div>
              {/* formik form */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddSubCategoryform;

import React from "react";
import { changerole } from "../../services/api";

function NormalPost({ posts }) {
  const moveToCorproate = async (user) => {
    try {
      let response = await changerole(user._id, { 
        role: "Corporate",
      });

      if (response.status === 200) {
        alert("User successfully moved to Corporate");
        window.location.reload();
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.error("Error moving user to corporate:", error);
      alert("Error changing user role");
    }
  };

  return (
    <>
      {posts &&
        posts.filter(user => user.role === "Customer").map((i, index) => (
          <tr key={i._id}>
            <td>{index + 1}</td>
            <td>{i.username}</td>
            <td>{i.phoneno}</td>
            <td>{i.email}</td>
            <td>{i.gst}</td>
            <td>{i.pan}</td>
            <td>{i.billingaddress1}</td>
            <td>{i.shippingaddress1}</td>
            <td>
              <button
                className="btn btn-success rounded"
                onClick={() => moveToCorproate(i)}
              >
                Move to Corporate
              </button>
            </td>
          </tr>
        ))}
    </>
  );
}

export default NormalPost;

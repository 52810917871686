import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Loging } from "../../services/api"; // Assume VerifyOTP is the function to verify OTP
import Footer from "../footer/Footer";
import { Formik, Form, ErrorMessage } from "formik";
import * as yup from "yup";

function Login() {
  const navigate = useNavigate();

  const [otpSent, setOtpSent] = useState(false); // Track if OTP was sent successfully
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    key: "",
    otp: "",
  });

  const LoginSchema = yup.object({
    username: yup.string().required("Required!").min(3, "Greater than 3 "),
    password: yup.string().required("Required!"),
    otp: otpSent ? yup.string().required("OTP is required!") : yup.string(),
    key: yup.string().required("Required"),
  });

  // Effect to check if already logged in (with a valid token)
  useEffect(() => {
    if (localStorage.getItem("token") && !otpSent) {
      navigate("/home");
    }
  }, [navigate, otpSent]);

  // Handle OTP submit and verification
  const handleOTPSubmit = async (values, actions) => {
    try {
      // Send OTP to the phone number if OTP is not sent
      if (!otpSent) {
        const response = await Loging(values);
        if (response.data) {
          setOtpSent(true);
          alert("OTP sent to your phone. Please enter it.");
        }
      } else {
        // Verify OTP

        const otpResponse = await Loging(values); // This should verify OTP against backend
        if (otpResponse.data && otpResponse.data.success) {
          // Set token only after OTP is successfully verified
          localStorage.setItem("token", otpResponse.data.authtoken);
          navigate("/home");
          alert("Login successful!");
          window.location.reload();
          actions.resetForm();
        } else {
          alert("Invalid OTP. Please try again.");
          actions.resetForm();
        }
      }
    } catch (error) {
      alert("Something went wrong. Please try again.");
      actions.resetForm();
    }
  };

  // Handle input change and update form data
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      {localStorage.getItem("token") && otpSent ? (
        <></> // Avoid showing login form if already logged in and OTP sent
      ) : (
        <div>
          <div className="row text-center">
            <div className="col-lg-5 mx-5 mr-1 mt-3">
              <Formik
                initialValues={formData}
                validationSchema={LoginSchema}
                onSubmit={handleOTPSubmit}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <div>
                      <img
                        alt="..."
                        src="/assets/Images/Logo.png"
                        className="rounded mx-auto d-block w-50 mt-5"
                      />
                      <div className="mt-5">
                        <h1 className="form__title mt-1">Admin Dashboard </h1>
                        <h6>Login to continue as an Admin</h6>

                        <div className="form__div mb-2 mx-5 mt-3">
                          <input
                            type="text"
                            className={`form__input form-control shadow-none mx-5 ${
                              formik.touched.username &&
                              formik.errors.username &&
                              "is-invalid"
                            }`}
                            placeholder=" "
                            name="username"
                            value={formik.values.username}
                            onChange={(e) => {
                              formik.handleChange(e);
                              handleInputChange(e);
                            }}
                          />
                          <label htmlFor="" className="form__label  mx-5">
                            Username <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>

                        <ErrorMessage
                          component="div"
                          name="username"
                          className="error"
                        />

                        <br />

                        <div className="form__div mb-2  mx-5">
                          <input
                            type="password"
                            className={`form__input form-control shadow-none  mx-5 ${
                              formik.touched.password &&
                              formik.errors.password &&
                              "is-invalid"
                            }`}
                            placeholder=" "
                            name="password"
                            value={formik.values.password}
                            onChange={(e) => {
                              formik.handleChange(e);
                              handleInputChange(e);
                            }}
                          />
                          <label htmlFor="" className="form__label  mx-5">
                            Password <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                        <ErrorMessage
                          component="div"
                          name="password"
                          className="error"
                        />
                        <br />

                        <div className="form__div mb-2 mx-5">
                          <input
                            type="text"
                            className={`form__input form-control shadow-none mx-5  ${
                              formik.touched.key &&
                              formik.errors.key &&
                              "is-invalid"
                            }`}
                            placeholder=" "
                            name="key"
                            value={formik.values.key}
                            onChange={(e) => {
                              formik.handleChange(e);
                              handleInputChange(e);
                            }}
                          />
                          <label htmlFor="" className="form__label mx-5 ">
                            Secret Key <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                        <br />
                        <ErrorMessage
                          component="div"
                          name="key"
                          className="error"
                        />
                        {otpSent && (
                          <div className="form__div mb-2 mx-5 mt-2">
                            <input
                              type="text"
                              className="form__input form-control shadow-none mx-5"
                              placeholder=" "
                              name="otp" // Ensure "otp" is used here as the name
                              value={formik.values.otp}
                              onChange={formik.handleChange} // Only use Formik's handleChange
                            />
                            <label htmlFor="" className="form__label mx-5 ">
                              OTP <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        )}

                        <input
                          type="submit"
                          className="btn btn-dark btn-lg"
                          value={otpSent ? "Verify OTP" : "Login"}
                        />

                        {/* OTP Input if OTP is sent */}

                        <div className="row mt-2 d-flex align-items-center justify-content-center">
                          <Link to="/signup">
                            <p
                              className="text-center"
                              style={{
                                color: "#1A73E8",
                                textDecoration: "none",
                              }}
                            >
                              Don't have an Account? Sign Up
                            </p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="col-lg-5 w-100 mt-2">
              <img
                src="/assets/Images/Loginim.svg"
                className="rounded float-right w-100 mt-5 "
                alt=" loading.."
              />
            </div>
          </div>
        </div>
      )}
      <br />
      <footer className="footer1 "></footer>
      <Footer />
    </>
  );
}

export default Login;

import React from "react";
import { deleteOccation } from "../../services/api";

function OccationalPost({ posts }) {
  const remove = async (i) => {
    let dat = await deleteOccation(i._id);
    if (dat.status) {
      alert("DELETED", dat.data);
      window.location.reload();
    } else {
      alert("Something went wrong");
      window.location.reload();
    }
  };

  return (
    <>
      {posts &&
        posts.map((i, index) => {
          return (
            <tr key={i._id}>
              <td>
                <span>{index + 1}</span>
              </td>
              <td>
                <span>{i.categoryname}</span>
              </td>
              <td>
                <span>
                  {i.subcategory.map((sub, idx) => (
                    <span key={idx}>{sub?.subcategory || sub}, </span>
                  ))}
                </span>
              </td>
              <td>
                <span>{i.Occasion}</span>
              </td>

              <th scope="col">
                <button
                  className="btn btn-danger m-2"
                  onClick={(e) => {
                    remove(i);
                  }}
                >
                  Remove
                </button>
              </th>
            </tr>
          );
        })}
    </>
  );
}

export default OccationalPost;

import React, { useState, useRef, useEffect } from "react";
import Footer from "../footer/Footer";
import JoditEditor from "jodit-react";
import { returnpolicy, returnpolicyget } from "../../services/api";
import { Form, Formik } from "formik";

function RP() {
  const editor = useRef(null);
  const [content, setContent] = useState("");

  useEffect(() => {
    // Fetch existing return policy when component mounts
    const fetchReturnPolicy = async () => {
      try {
        const response = await returnpolicyget();
        if (response && response.data && response.data.length > 0) {
          // Get the most recent return policy
          const latestPolicy = response.data[0];
          setContent(latestPolicy.returnpolicy);
        }
      } catch (error) {
        console.error("Error fetching return policy:", error);
      }
    };

    fetchReturnPolicy();
  }, []);

  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h4 className="page-title">Return Policy</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title mb-4">Return Policy</h4>

                    <Formik
                      enableReinitialize
                      initialValues={{
                        returnpolicy: content,
                      }}
                      onSubmit={async (values, actions) => {
                        try {
                          let data = await returnpolicy({ returnpolicy: content });
                          if (data.status) {
                            alert("SUCCESSFULLY UPDATED");
                          } else {
                            alert("Something went wrong");
                          }
                        } catch (error) {
                          console.error("Error updating return policy:", error);
                          alert("Error updating return policy");
                        }

                        actions.resetForm();
                        window.location.reload();
                      }}
                    >
                      {(formik) => (
                        <Form>
                          <div className="table-responsive">
                            <JoditEditor
                              ref={editor}
                              value={content}
                              tabIndex={1}
                              onBlur={(newContent) => setContent(newContent)}
                              onChange={(newContent) => {}}
                            />
                            <input
                              type="submit"
                              className="btn mt-4 rounded-3 w-20 btn-lg btn-outline-secondary btn-dark"
                              value="Submit"
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default RP;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { deletedsubsubcate, SubSubUpdateCategory } from "../../../services/api";

function Subsubposts({ posts }) {
  const [description, setDescription] = useState("");

  const remove = async (i) => {
    let dat = await deletedsubsubcate(i._id);
    if (dat.status) {
      alert("DELETED", dat.data);
      window.location.reload();
    } else {
      alert("Something went wrong");
      window.location.reload();
    }
  };

  const handleToggle = async (post, field) => {
    try {
      const updatedData = {
        ...post,
        [field]: !post[field]
      };
      
      const response = await SubSubUpdateCategory(post._id, updatedData);
      
      if (response.status) {
        window.location.reload();
      } else {
        alert("Failed to update status");
      }
    } catch (error) {
      alert("Error updating status");
      console.error(error);
    }
  };

  const detailsChangeHandler = (desc) => {
    setDescription(desc ? desc.replace(/<[^>]+>/g, "") : "No description available");
  };

  return (
    <>
      {posts &&
        posts.map((i) => (
          <tr key={i._id}>
            <td style={{ verticalAlign: 'middle' }}><span>{i.categoryname}</span></td>
            <td style={{ verticalAlign: 'middle' }}><span>{i.subcategoryname}</span></td>
            <td style={{ verticalAlign: 'middle' }}><span>{i.subsubcategory}</span></td>
            <td style={{ verticalAlign: 'middle' }}>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                data-bs-toggle="modal"
                data-bs-target={`#descriptionModal_${i._id}`}
                onClick={() => detailsChangeHandler(i.description)}
              >
                Details
              </button>

              {/* Modal for this specific item */}
              <div
                className="modal fade"
                id={`descriptionModal_${i._id}`}
                tabIndex="-1"
                aria-labelledby={`descriptionModalLabel_${i._id}`}
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Description - {i.subsubcategory}</h5>
                      <button
                        type="button"
                        className="btn rounded btn-md my-1 btn-outline-secondary btn-dark"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fa fa-times" style={{ color: "white" }}></i>
                      </button>
                    </div>
                    <div className="modal-body">
                      <label className="modal-body__content">
                        {description || "No description available"}
                      </label>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <img
                src={i.subsubcategoryimg}
                alt=".."
                height="80px"
                width="80px"
                className="img-thumbnail"
              />
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <div className="form-check form-switch">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={i.isFeatured}
                  onChange={() => handleToggle(i, 'isFeatured')}
                  style={{ cursor: 'pointer' }}
                />
                <label className="form-check-label">
                  <span className={`badge ${i.isFeatured ? 'bg-success' : 'bg-secondary'}`}>
                    {i.isFeatured ? 'Yes' : 'No'}
                  </span>
                </label>
              </div>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <div className="form-check form-switch">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={i.isTopProduct}
                  onChange={() => handleToggle(i, 'isTopProduct')}
                  style={{ cursor: 'pointer' }}
                />
                <label className="form-check-label">
                  <span className={`badge ${i.isTopProduct ? 'bg-success' : 'bg-secondary'}`}>
                    {i.isTopProduct ? 'Yes' : 'No'}
                  </span>
                </label>
              </div>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Link to={`/update/subsub/${i._id}`}>
                <button className="btn btn-dark btn-sm me-2">
                  <i className="fa-solid fa-pen-to-square"></i>
                </button>
              </Link>
              <button
                className="btn btn-danger btn-sm"
                onClick={(e) => {
                  remove(i);
                }}
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
    </>
  );
}

export default Subsubposts;

import React, { useState } from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import { Register } from "../../services/api";
import Footer from "../footer/Footer";

function Signup() {
  const navigate = useNavigate();
  const [otpSent, setOtpSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // State to store form data
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    key: "",
    role: "",
    otp: "",
  });

  const LoginSchema = yup.object({
    username: yup.string().required("Required!").min(3, "Too small"),
    email: yup.string().required("Required!").email("Invalid"),
    otp: otpSent ? yup.string().required("OTP is required!") : yup.string(),
    password: yup
      .string()
      .required("Required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      )
      .min(8, "length should be 8 char"),
    key: yup.string().required("Required"),
    role: yup.string().required("Please select a role"),
  });

  const handleOtpSubmit = async (values, actions) => {
    try {
      // Call your API to handle OTP validation
      const otpResponse = await Register(values);
      if (otpResponse.status === 200) {
        setOtpSent(true); // OTP is successfully validated
        alert("OTP verified, now proceed with user creation.");
        actions.setFieldValue('otp', ''); // Reset OTP field only
      } else {
        alert("OTP verification failed!");
      }
    } catch (error) {
      alert("Error occurred during OTP verification!");
    }
    setIsSubmitting(false);
  };

  const handleFormSubmit = async (values, actions) => {
    try {
      setIsSubmitting(true);
      const response = await Register(values);
      if (response.status === 200) {
        alert(`Successfully created ${values.role} account`);
        navigate("/"); // Redirect to login or dashboard
      } else {
        alert("Something went wrong");
      }
      actions.resetForm(); // Reset the form for the next use case
    } catch (error) {
      alert(error.message);
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      {localStorage.getItem("token") ? (
        (window.location.href = "/home")
      ) : (
        <>
          <div className="row text-center d-flex">
            <div className="col-lg-5 mx-5 mr-1 mt-3">
              <Formik
                initialValues={formData}
                validationSchema={LoginSchema}
                onSubmit={otpSent ? handleFormSubmit : handleOtpSubmit}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <div>
                      <img
                        alt="Logo"
                        src="/assets/Images/Logo.png"
                        className="rounded w-50 mx-auto d-block mt-5"
                      />
                      <div>
                        <h1 className="form__title mt-4">Admin Dashboard</h1>
                        <h6>Signup to continue as an Admin</h6>

                        <div className="form__div mx-5 mb-2 mt-3">
                          <select
                            className={`form__input form-control shadow-none mx-5 ${formik.touched.role && formik.errors.role && "is-invalid"}`}
                            name="role"
                            value={formik.values.role}
                            onChange={(e) => {
                              formik.handleChange(e);
                              handleInputChange(e);
                            }}
                          >
                            <option value="">Select Role</option>
                            <option value="Admin">Admin</option>
                            <option value="Preorder">Preorder</option>
                          </select>
                          <label htmlFor="" className="form__label mx-5">
                            Role <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                        <ErrorMessage component="div" name="role" className="error" />

                        <div className="form__div mx-5 mb-1 mt-3">
                          <input
                            type="text"
                            className={`form__input form-control shadow-none mx-5 ${formik.touched.username && formik.errors.username && "is-invalid"}`}
                            placeholder=" "
                            name="username"
                            value={formik.values.username}
                            onChange={(e) => {
                              formik.handleChange(e);
                              handleInputChange(e);
                            }}
                          />
                          <label htmlFor="" className="form__label mx-5">
                            Username <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                        <ErrorMessage component="div" name="username" className="error" />

                        <div className="form__div mx-5 mb-2 mt-3">
                          <input
                            type="email"
                            className={`form__input form-control shadow-none mx-5 ${formik.touched.email && formik.errors.email && "is-invalid"}`}
                            placeholder=" "
                            name="email"
                            value={formik.values.email}
                            onChange={(e) => {
                              formik.handleChange(e);
                              handleInputChange(e);
                            }}
                          />
                          <label htmlFor="" className="form__label mx-5">
                            Email <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                        <ErrorMessage component="div" name="email" className="error" />

                        <div className="form__div mx-5 mb-2 mt-3">
                          <input
                            type="password"
                            className={`form__input form-control shadow-none mx-5 ${formik.touched.password && formik.errors.password && "is-invalid"}`}
                            placeholder=" "
                            name="password"
                            value={formik.values.password}
                            onChange={(e) => {
                              formik.handleChange(e);
                              handleInputChange(e);
                            }}
                          />
                          <label htmlFor="" className="form__label mx-5">
                            Password <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                        <ErrorMessage component="div" name="password" className="error" />

                        <div className="form__div mx-5 mb-2 mt-3">
                          <input
                            type="text"
                            className={`form__input form-control shadow-none mx-5 ${formik.touched.key && formik.errors.key && "is-invalid"}`}
                            placeholder=" "
                            name="key"
                            value={formik.values.key}
                            onChange={(e) => {
                              formik.handleChange(e);
                              handleInputChange(e);
                            }}
                          />
                          <label htmlFor="" className="form__label mx-5">
                            Secret Key <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                        <ErrorMessage component="div" name="key" className="error" />

                        {otpSent && (
                          <div className="form__div mx-5 mb-1 mt-3">
                            <input
                              type="text"
                              className={`form__input form-control shadow-none mx-5 ${formik.touched.otp && formik.errors.otp && "is-invalid"}`}
                              placeholder=" "
                              name="otp"
                              value={formik.values.otp}
                              onChange={(e) => {
                                formik.handleChange(e);
                                handleInputChange(e);
                              }}
                            />
                            <label htmlFor="" className="form__label mx-5">
                              OTP <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        )}

                        <ErrorMessage component="div" name="otp" className="error" />

                        <input
                          type="submit"
                          className="btn btn-dark mt-3 btn-lg"
                          value={!otpSent ? "Sign Up" : "Verify OTP"}
                          disabled={isSubmitting}
                        />

                        <div className="row d-flex mt-2 align-items-center justify-content-center">
                          <Link to="/">
                            <p
                              className="text-center"
                              style={{
                                color: "#1A73E8",
                                textDecoration: "none",
                              }}
                            >
                              Already have an Account? Login
                            </p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="col-lg-5 w-100 mt-2">
              <img
                src="/assets/Images/Loginim.svg"
                className="rounded float-right w-100 mt-5"
                alt=" loading.."
              />
            </div>
          </div>
          <footer className="footer1"></footer>
          <Footer />
        </>
      )}
    </>
  );
}

export default Signup;

import React, { useEffect, useState } from "react";
import { Orderget } from "../../services/api";
import Pagination from "../categories/categories/Pagination";
import Footer from "../footer/Footer";
import OrdersPosts from "./OrdersPosts";

function Orders() {
  const [ord, setord] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(25);
  const [searchedvalue, setsearchedvalue] = useState("");
  const [noResults, setNoResults] = useState(false);
  const [sortOrder, setSortOrder] = useState(() => {
    return localStorage.getItem("ordersSortOrder") || "desc";
  });

  useEffect(() => {
    async function fetchData() {
      let dat = await Orderget();
      console.log(dat)
      if (dat) {
        setord(dat.data.data.orders);
      } else {
        alert("Something went wrong in getting data.");
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (ord.length > 0) {
      const sortedOrders = [...ord].sort((a, b) => {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      });
      setord(sortedOrders);
    }
  }, [sortOrder, ord]);

  useEffect(() => {
    localStorage.setItem("ordersSortOrder", sortOrder);
  }, [sortOrder]);

  const Totalpages = Math.ceil(ord.length / postsPerPage) || 0;
  const pages = [...Array(Totalpages + 1).keys()].slice(1);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = ord.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleSortClick = () => {
    setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchedvalue) {
      // Use the Orderget function to fetch the filtered orders based on phone number
      const dat = await Orderget(searchedvalue); // Pass searched value to API
      if (dat && dat.data.data.orders.length > 0) {
        setord(dat.data.data.orders);
        setNoResults(false);
      } else {
        setord([]);
        setNoResults(true);
      }
    }
  };

  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h4 className="page-title">Orders</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title w-25 mb-4">Order List</h4>
                    <div className="row">
                      <div className="col-8 mt-4">
                        <form className="d-flex mb-2" role="search">
                          <div className="btn-group">
                            <input
                              className="form-control mx-2"
                              type="search"
                              value={searchedvalue}
                              placeholder="Search Phone Number"
                              onChange={(e) => setsearchedvalue(e.target.value)}
                              aria-label="Search"
                            />
                            <button
                              type="button"
                              className="btn bg-transparent border-none"
                              onClick={async () => {
                                setsearchedvalue("");
                                let dat = await Orderget();
                                setord(dat.data.data.orders);
                                setNoResults(false);
                              }}
                            >
                              <i
                                className="fa fa-times"
                                style={{ color: "white" }}
                              ></i>
                            </button>
                            <button
                              className="btn rounded btn-md btn-outline-secondary btn-dark"
                              type="submit"
                              onClick={handleSearch} // Use the new handleSearch function
                            >
                              Search
                            </button>
                          </div>
                        </form>
                      </div>
                      <div className="col-4 mb-2">
                        <button
                          className="btn btn-outline-primary text-white"
                          onClick={handleSortClick}
                        >
                          {sortOrder === "asc"
                            ? "Oldest First"
                            : "Newest First"}
                        </button>
                      </div>
                    </div>

                    <div className="table-responsive">
                      {noResults ? (
                        <div className="text-center mt-4">
                          <p>No user with this phone number.</p>
                        </div>
                      ) : (
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">Sr no</th>
                              <th>Products</th>
                              <th scope="col">User Name</th>
                              <th scope="col">Phone no</th>
                              <th scope="col">Quantity</th>
                              <th scope="col">Price</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Status</th>
                              <th scope="col" colSpan="5">
                                Action
                              </th>
                              <th scope="col">Details</th>
                              <th scope="col">Imported</th>
                              <th scope="col">Date Created</th>
                              <th scope="col">Timestamp</th>
                            </tr>
                          </thead>
                          <tbody>
                            <OrdersPosts posts={currentPosts} />
                          </tbody>
                        </table>
                      )}
                    </div>

                    <Pagination
                      postsPerPage={postsPerPage}
                      totalPosts={Totalpages}
                      paginate={paginate}
                      currentPage={currentPage}
                      pageNumbers={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Orders;

import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { EstPartners, UploadFile } from "../../services/api";
import * as yup from "yup";

function EstPartnerForm() {
  const validate = yup.object({
    imageUrl: yup.mixed().nullable().required("Required"),
    imageNum: yup.string().required("Required"),
  });

  return (
    <>
      <Formik
        initialValues={{
          imageNum: "",
          imageUrl: "",
        }}
        validationSchema={validate}
        onSubmit={async (values, actions) => {
          try {
            const data = new FormData();
            if (values.imageUrl) {
              data.append("name", values.imageUrl.name);
              data.append("file", values.imageUrl);
              const image = await UploadFile(data);
              values.imageUrl = image.data;
            }

            let response = await EstPartners(values);

            if (!response.status) {
              alert("Something went wrong");
            } else {
              alert("Successfully Created");
              window.location.reload();
            }
          } catch (error) {
            console.error("Error:", error);
            alert("Error uploading image");
          }

          actions.resetForm();
        }}
      >
        {(formik) => (
          <Form>
            <label>Partner Image Number</label>
            <Field
              as="select"
              className={`form-control shadow-none ${
                formik.touched.imageNum && formik.errors.imageNum && "is-invalid"
              }`}
              name="imageNum"
            >
              <option value="">Select Image Position</option>
              <option value="1">Image 1</option>
              <option value="2">Image 2</option>
              <option value="3">Image 3</option>
              <option value="4">Image 4</option>
              <option value="5">Image 5</option>
              <option value="6">Image 6</option>
              <option value="7">Image 7</option>
              <option value="8">Image 8</option>
              <option value="9">Image 9</option>
              <option value="10">Image 10</option>
              <option value="11">Image 11</option>
              <option value="12">Image 12</option>
            </Field>
            <ErrorMessage name="imageNum" component="div" className="error" />

            <br />
            <label>Partner Image</label>
            <input
              type="file"
              accept="image/*"
              className={`form-control shadow-none mt-2 ${
                formik.touched.imageUrl && formik.errors.imageUrl && "is-invalid"
              }`}
              name="imageUrl"
              onChange={(e) =>
                formik.setFieldValue("imageUrl", e.target.files[0])
              }
            />
            <ErrorMessage component="div" name="imageUrl" className="error" />

            <br />
            <input
              type="submit"
              className="btn mt-2 rounded-3 w-20 btn-lg btn-outline-secondary btn-dark"
              value="Submit"
            />
          </Form>
        )}
      </Formik>
    </>
  );
}

export default EstPartnerForm; 
import React, { useState, useEffect } from "react";
import Pagination from "../categories/categories/Pagination";
import { corporatefilter, corporateusers, pendingCorporateUsers, changerole } from "../../services/api";
import Footer from "../footer/Footer";
import CorporatePosts from "./CorporatePosts";

function Corporate() {
  const [posts, setposts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(25);
  const [searchedvalue, setsearchedvalue] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const approvedUsers = await corporateusers();
        const pendingUsers = await pendingCorporateUsers();
        
        // Combine and filter users
        const combinedUsers = [
          ...(approvedUsers?.data || []),
          ...(pendingUsers?.data || [])
        ].filter(user => 
          user.role === "Corporate" || user.beCorporate === true
        );
        
        setposts(combinedUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
        setposts([]);
      }
    }

    fetchData();
  }, []);

  // Calculate pagination only if posts exist
  const Totalpages = Math.ceil((posts?.length || 0) / postsPerPage);
  const pages = [...Array(Totalpages + 1).keys()].slice(1);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Handle search
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      if (searchedvalue) {
        const result = await corporatefilter(searchedvalue);
        const filteredUsers = (result?.data?.data || []).filter(user => 
          user.role === "Corporate" || user.beCorporate === true
        );
        setposts(filteredUsers);
      } else {
        const approvedUsers = await corporateusers();
        const pendingUsers = await pendingCorporateUsers();
        const combinedUsers = [
          ...(approvedUsers?.data || []),
          ...(pendingUsers?.data || [])
        ].filter(user => 
          user.role === "Corporate" || user.beCorporate === true
        );
        setposts(combinedUsers);
      }
    } catch (error) {
      console.error("Error searching:", error);
      setposts([]);
    }
  };

  // Handle clear search
  const handleClear = async () => {
    try {
      setsearchedvalue("");
      const approvedUsers = await corporateusers();
      const pendingUsers = await pendingCorporateUsers();
      const combinedUsers = [
        ...(approvedUsers?.data || []),
        ...(pendingUsers?.data || [])
      ].filter(user => 
        user.role === "Corporate" || user.beCorporate === true
      );
      setposts(combinedUsers);
    } catch (error) {
      console.error("Error clearing search:", error);
      setposts([]);
    }
  };

  // Handle role change
  const handleRoleChange = async (userId, makeNormal) => {
    try {
      const response = await changerole(userId, { 
        role: makeNormal ? "Customer" : "Corporate"
      });
      
      if (response.status === 200) {
        // Refresh the data after role change
        const approvedUsers = await corporateusers();
        const pendingUsers = await pendingCorporateUsers();
        const combinedUsers = [
          ...(approvedUsers?.data || []),
          ...(pendingUsers?.data || [])
        ].filter(user => 
          user.role === "Corporate" || user.beCorporate === true
        );
        setposts(combinedUsers);
        alert(`User changed to ${makeNormal ? 'Customer' : 'Corporate'} successfully`);
      }
    } catch (error) {
      console.error("Error changing role:", error);
      alert("Error changing user role");
    }
  };

  return (
    <>
      {localStorage.getItem("token") ? (
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="page-title-box">
                <div className="row align-items-center"></div>
              </div>

              <div className="row">
                <div className="col-xl-12">
                  <div className="card m-b-30">
                    <div className="card-body">
                      <h4 className="mt-0 header-title mb-4">Corporate List</h4>
                      <form
                        className="d-flex mb-2"
                        style={{ width: "30%" }}
                        role="search"
                        onSubmit={handleSearch}
                      >
                        <div className="btn-group">
                          <input
                            className="form-control mx-2 btn-close"
                            type="search"
                            value={searchedvalue}
                            placeholder="Search email"
                            onChange={(e) => setsearchedvalue(e.target.value)}
                            aria-label="Search"
                          />
                          <button
                            type="button"
                            className="btn bg-transparent"
                            style={{ left: "-43px" }}
                            onClick={handleClear}
                          >
                            <i className="fa fa-times" style={{ color: "white" }}></i>
                          </button>
                          <button
                            className="btn rounded btn-md btn-outline-secondary btn-dark"
                            type="submit"
                          >
                            Search
                          </button>
                        </div>
                      </form>

                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">Sr No</th>
                              <th scope="col">Name</th>
                              <th scope="col">Mobile no</th>
                              <th scope="col">Email</th>
                              <th scope="col">GST</th>
                              <th scope="col">Pan number</th>
                              <th scope="col">Organization Name</th>
                              <th scope="col">Billing Address</th>
                              <th scope="col">Shipping Address</th>
                              <th scope="col">Status</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <CorporatePosts 
                              posts={currentPosts} 
                              onRoleChange={handleRoleChange} 
                            />
                          </tbody>
                        </table>
                      </div>

                      {posts.length > 0 && (
                        <Pagination
                          postsPerPage={postsPerPage}
                          totalPosts={Totalpages}
                          paginate={(pageNumber) => setCurrentPage(pageNumber)}
                          currentPage={currentPage}
                          pageNumbers={pages}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        window.location.href = "/"
      )}
    </>
  );
}

export default Corporate;

import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import TextField from "../categories/TextField";
import Footer from "../footer/Footer";
import JoditEditor from "jodit-react";
import { UploadFile, getOneService, updateService } from "../../services/api";

function UpdateService() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [service, setService] = useState({});
  const editor = useRef(null);

  useEffect(() => {
    async function fetchService() {
      const data = await getOneService(id);
      setService(data);
    }
    fetchService();
  }, [id]);

  const validate = yup.object({
    service: yup.string().required("Required"),
    descr: yup.string().required("Required"),
    img: yup.mixed().nullable(),
  });

  return (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-title">Update Service</h4>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card m-b-30">
                <div className="card-body">
                  <h4 className="mt-0 header-title mb-4">Update Service</h4>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      service: service.service || "",
                      descr: service.descr || "",
                      img: service.img || "",
                      img1: service.img1 || "",
                      img2: service.img2 || "",
                      img3: service.img3 || "",
                      img4: service.img4 || "",
                      img5: service.img5 || "",
                    }}
                    validationSchema={validate}
                    onSubmit={async (values, actions) => {
                      try {
                        // Handle image uploads
                        const imageFields = ['img', 'img1', 'img2', 'img3', 'img4', 'img5'];
                        for (const field of imageFields) {
                          if (values[field] && values[field] instanceof File) {
                            const data = new FormData();
                            data.append("name", values[field].name);
                            data.append("file", values[field]);
                            const image = await UploadFile(data);
                            values[field] = image.data;
                          }
                        }

                        const response = await updateService(id, values);
                        if (response.status) {
                          alert("Service Updated Successfully");
                          navigate("/service");
                        } else {
                          alert("Something went wrong");
                        }
                      } catch (error) {
                        alert("Error updating service");
                        console.error(error);
                      }
                    }}
                  >
                    {(formik) => (
                      <Form>
                        <TextField label="Service Name" name="service" />
                        <br />

                        <label>Description</label>
                        <JoditEditor
                          ref={editor}
                          value={formik.values.descr}
                          tabIndex={1}
                          onBlur={(newContent) => 
                            formik.setFieldValue("descr", newContent)
                          }
                          onChange={(newContent) => 
                            formik.setFieldValue("descr", newContent)
                          }
                        />
                        <ErrorMessage name="descr" className="error" component="div" />
                        <br />

                        {/* Image upload fields */}
                        {['img', 'img1', 'img2', 'img3', 'img4', 'img5'].map((field, index) => (
                          <div key={field}>
                            <label>Image {index + 1}</label>
                            {service[field] && (
                              <div className="mb-2">
                                <img 
                                  src={service[field]} 
                                  alt={`Current ${field}`} 
                                  height="100"
                                  className="mb-2"
                                />
                              </div>
                            )}
                            <input
                              type="file"
                              accept="image/jpg, image/png, image/webp"
                              onChange={(e) =>
                                formik.setFieldValue(field, e.target.files[0])
                              }
                              className="form-control shadow-none mb-3"
                            />
                          </div>
                        ))}

                        <input
                          type="submit"
                          className="btn mt-2 rounded-3 w-20 btn-lg btn-outline-secondary btn-dark"
                          value="Update"
                        />
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default UpdateService; 
import React, { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import * as yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";
import {
  createOccation,
  dodfilter,
  getAllCategory,
  getAllSubCategory,
  getOccation,
  updateOccation,
} from "../../services/api";
import "../../App.css";
import Pagination from "../categories/categories/Pagination";
import Inputfielded from "./Inputfielded";
import Multiselect from "multiselect-react-dropdown";
import OccationalPost from "./OccationalPost";

function Occatonal() {
  const [posts, setposts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(25);

  // total no of pages
  const Totalpages = Math.ceil(posts.length / postsPerPage);
  const pages = [...Array(Totalpages + 1).keys()].slice(1);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const validate = yup.object({
    categoryname: yup.string().required("Required"),
    Occasion: yup.string().required("Required"),
    subcategory: yup.array().required("Required"),
  });

  const [filters, setfilters] = useState([]); // for subcategories
  const [category, setcategory] = useState([]); // for categories
  const [subCategory, setsubCategory] = useState([]); // for subcategories (all data)
  const [tags2, settags2] = useState([]); // selected tags
  const [searchedvalue, setsearchedvalue] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch data from APIs
        const dealResponse = await getOccation();
        const category = await getAllCategory();
        const subCategory = await getAllSubCategory();

        console.log(subCategory, "subCategory");

        // Extract data safely
        if (dealResponse) {
          setposts(dealResponse);
        }
        if (category) {
          setcategory(category);
        }
        if (subCategory) {
          setsubCategory(subCategory);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  // Handle category change and filter subcategories
  const handleCategoryChange = (event) => {
    const selectedCategoryName = event.target.value; // Selected category name
    if (selectedCategoryName) {
      // Filter subcategories based on the selected category ID (category._id)
      const selectedCategory = category.find(
        (cat) => cat.title === selectedCategoryName
      );
      if (selectedCategory) {
        const filteredSubCategories = subCategory.filter(
          (sub) => sub.categoryid.toString() === selectedCategory._id.toString()
        );
        setfilters(filteredSubCategories);
      }
    } else {
      setfilters([]);
    }
  };

  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h4 className="page-title">Occationals</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title mb-4">Occation</h4>

                    <div className="table-responsive">
                      <Formik
                        enableReinitialize
                        initialValues={{
                          categoryname: "",
                          Occasion: "",
                          subcategory: [],
                        }}
                        validationSchema={validate}
                        onSubmit={async (values) => {
                          let data = {
                            categoryname: values.categoryname,
                            Occasion: values.Occasion,
                            subcategory: tags2,
                          };
                          console.log(values._id);
                          // To update the occation, need to pass the id of the occation
                          let dat = await createOccation(data);
                          if (dat.status) {
                            alert("Occation Added");
                            window.location.reload();
                          } else {
                            alert("Something went wrong");
                          }
                        }}
                      >
                        {(formik) => (
                          <Form>
                            <div className=" row mx-1">
                              <div className="col-12">
                                <label> Category Name</label>
                                <Field
                                  as="select"
                                  name="categoryname"
                                  className="form-control"
                                  value={formik.values.categoryname} // Bind the selected value
                                  onChange={(e) => {
                                    formik.handleChange(e); // Handle Formik value change
                                    handleCategoryChange(e); // Filter subcategories based on the selected category ID
                                  }}
                                >
                                  <option value="">Select Category</option>
                                  {category.map((i) => {
                                    return (
                                      <option key={i._id} value={i.title}>
                                        {" "}
                                        {/* Store category name here */}
                                        {i.title}
                                      </option>
                                    );
                                  })}
                                </Field>

                                <ErrorMessage
                                  component="div"
                                  name="categoryname"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className=" row mx-1 mt-3">
                              <div className="col">
                                <Inputfielded
                                  label="Occation"
                                  name="Occasion"
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <label>SubCategory Name</label>
                              <Multiselect
                                options={filters} // Ensure each filter has 'id' and 'subcategory'
                                displayValue="subcategory" // Display the subcategory name
                                onSelect={(selectedList) => {
                                  // Store only the 'id' of the selected subcategories
                                  const selectedIds = selectedList.map(
                                    (item) => item._id
                                  ); // Ensure you're using _id, not id
                                  settags2(selectedIds); // Update the state with only 'id's
                                }}
                                onRemove={(removedList) => {
                                  // Handle the removal and update the state accordingly
                                  const removedIds = removedList.map(
                                    (item) => item._id
                                  ); // Ensure you're using _id, not id
                                  settags2(removedIds); // Update the state with only 'id's
                                }}
                                style={{
                                  chips: {
                                    background: "black",
                                  },
                                }}
                                placeholder="Select SubCategory"
                                avoidHighlightFirstOption={true}
                              />
                            </div>

                            <ErrorMessage
                              name="subcategory"
                              component="div"
                              className="error"
                            />

                            <input
                              type="submit"
                              className="btn mt-2 mx-4 rounded-3 w-20  btn-lg btn-outline-secondary btn-dark"
                              value="Submit"
                            />
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title mb-4">Occation List</h4>

                    <form
                      className="d-flex mb-2 "
                      style={{ width: "50%" }}
                      role="search"
                    >
                      <div className="btn-group">
                        <input
                          className="form-control  mx-2  btn-close"
                          type="search"
                          value={searchedvalue}
                          placeholder="Search Product Name"
                          onChange={(e) => {
                            setsearchedvalue(e.target.value);
                          }}
                          aria-label="Search Product Name"
                        />
                        <button
                          type="button"
                          className="btn bg-transparent"
                          style={{ left: "-43px" }}
                          onClick={async () => {
                            let dat = await getOccation();
                            setposts(dat.data);
                            setsearchedvalue("");
                          }}
                        >
                          <i
                            className="fa fa-times"
                            style={{ color: "white" }}
                          ></i>
                        </button>
                        <button
                          className="btn rounded btn-md btn-outline-secondary btn-dark"
                          type="submit"
                          onClick={async (e) => {
                            e.preventDefault();
                            if (searchedvalue) {
                              let dat = await dodfilter(searchedvalue);
                              setposts(dat.data);
                            } else {
                              let dat = await getOccation();
                              setposts(dat.data);
                            }
                          }}
                        >
                          Search
                        </button>
                      </div>
                    </form>

                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col"> Sr No.</th>
                            <th scope="col"> Cateogory Name</th>
                            <th scope="col"> SubCategory Name</th>
                            <th scope="col"> Occation</th>

                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <OccationalPost posts={currentPosts} />
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      postsPerPage={postsPerPage}
                      totalPosts={Totalpages}
                      paginate={paginate}
                      currentPage={currentPage}
                      pageNumbers={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Occatonal;

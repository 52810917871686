import React from "react";
import { Link } from "react-router-dom";
import { Productdelete, VariantDelete } from "../../services/api";

function ManagePosts({ posts }) {
  const remove = async (j) => {
    let dat=null;
    if(j.mainProductId){
      dat=await VariantDelete(j._id);
    }else{
      dat=await Productdelete(j._id);
    }
    if (dat.status) {
      alert(" DELETED", dat.data);
      window.location.reload();
    } else {
      alert("Something went wrong");
      window.location.reload();
    }
  };

  console.log("posts", posts);

  return (
    <>
      {posts &&
        posts.map((j) => (
          <tr key={j._id}>
            <td>
              <span>{j.productname1}</span>
            </td>
            <td>
              <img src={j.imgs1} alt="..." height="80px" width="80px" />
            </td>
            <td>
              <span>{j.price2A}</span>
            </td>
            <td>
              <span>{j.discountprice2B}</span>
            </td>
            <td>
              <span>{j.stock6}</span>
            </td>

            <th scope="col">
              <Link
                to={
                  j.mainProductId
                    ? `/update/pro/${j.mainProductId}/${j._id}`
                    : `/update/pro/${j._id}`
                }
              >
                <button className="btn btn-dark btn-lg">
                  <i className="fa-solid fa-pen-to-square"></i>
                </button>
              </Link>
              <button
                className="btn btn-danger btn-lg mx-2"
                onClick={(e) => {
                  remove(j);
                }}
              >
                Remove
              </button>
            </th>
          </tr>
        ))}
    </>
  );
}

export default ManagePosts;

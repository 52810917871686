import { React, useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import TextField from "../TextField";
import * as yup from "yup";
import JoditEditor from "jodit-react";
import { getAllCategory, getAllSubCategory, SubSubCategory, UploadFile } from "../../../services/api";
/* eslint-disable */
function AddSubSubcategoryform() {
  const editor = useRef(null);
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/webp"];
  
  const validate = yup.object({
    categoryid: yup.string().required("Required"),
    subcategoryid: yup.string().required("Required"),
    subsubcategory: yup.string().required("Required"),
    subsubcategoryimg: yup
      .mixed()
      .nullable()
      .required("Required")
      .test(
        "FILE_FORMAT",
        "Uploaded file has unsupported format. Only PNG, JPG and WEBP files are allowed",
        (value) => !value || SUPPORTED_FORMATS.includes(value?.type)
      ),
    description: yup.string(),
    isFeatured: yup.boolean(),
    isTopProduct: yup.boolean()
  });

  const [state, setstate] = useState([]);
  const [state1, setstate1] = useState([]);
  const [categoriesWithSubs, setCategoriesWithSubs] = useState([]);

  useEffect(() => {
    async function data() {
      let categories = await getAllCategory();
      let subcategories = await getAllSubCategory();

      // Create a Set of category IDs that have subcategories
      const categoryIdsWithSubs = new Set(subcategories.map(sub => sub.categoryid));
      
      // Filter categories to only include those that have subcategories
      const filteredCategories = categories.filter(cat => categoryIdsWithSubs.has(cat._id));

      setstate1(subcategories);
      setCategoriesWithSubs(filteredCategories);
      setstate(categories);
    }
    data();
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card m-b-30">
            <div className="card-body">
              <h4 className="mt-0 header-title mb-4">Add Sub Sub Category</h4>
              <div className="table-responsive">
                <Formik
                  initialValues={{
                    categoryid: "",
                    subcategoryid: "",
                    subsubcategory: "",
                    subsubcategoryimg: "",
                    description: "",
                    isFeatured: false,
                    isTopProduct: false
                  }}
                  validationSchema={validate}
                  onSubmit={async (values, actions) => {
                    try {
                      if (values.subsubcategoryimg) {
                        const data = new FormData();
                        data.append("name", values.subsubcategoryimg.name);
                        data.append("file", values.subsubcategoryimg);
                        let subimg = await UploadFile(data);

                        values.subsubcategoryimg = subimg.data;
                      }
                    } catch (error) {
                      alert(error);
                    }
                    try {
                      let response = await SubSubCategory(values);

                      if (response.status) {
                        alert("SUCCESSFULLY CREATED ");
                        window.location.reload()
                      } else {
                        alert("something went wrong");
                      }
                      actions.resetForm();
                    } catch (error) {
                      alert(error);
                    }
                  }}
                >
                  {(formik) => (
                    <Form>
                      <label>Category Name</label>
                      <Field
                        as="select"
                        className={`form-control shadow-none ${
                          formik.touched.categoryid &&
                          formik.errors.categoryid &&
                          "is-invalid"
                        }`}
                        name="categoryid"
                      >
                        <option value="">Select Category</option>
                        {categoriesWithSubs &&
                          categoriesWithSubs.map((i) => (
                            <option key={i._id} value={i._id}>
                              {i.title}
                            </option>
                          ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="categoryid"
                        className="error"
                      />
                      <br />
                      <label>Sub Category Name</label>
                      <Field
                        as="select"
                        className={`form-control shadow-none ${
                          formik.touched.subcategoryid &&
                          formik.errors.subcategoryid &&
                          "is-invalid"
                        }`}
                        name="subcategoryid"
                        disabled={!formik.values.categoryid}
                      >
                        <option value="">Select Sub Category</option>
                        {state1 &&
                          state1.map((i) => {
                            if (formik.values.categoryid === i.categoryid) {
                              return (
                                <option key={i._id} value={i._id}>
                                  {i.subcategory}
                                </option>
                              );
                            }
                            return null;
                          })}
                      </Field>
                      <ErrorMessage
                        component="div"
                        className="error"
                        name="subcategoryid"
                      />

                      <br />
                      <TextField
                        label="Sub Sub Category Name"
                        name="subsubcategory"
                      />
                      <br />
                      <label>Sub Sub Category Image</label>
                      <input
                        type="file"
                        accept="image/png, image/jpg, image/jpeg, image/webp"
                        onChange={(e) => formik.setFieldValue("subsubcategoryimg", e.target.files[0])}
                        className={`form-control shadow-none ${formik.touched.subsubcategoryimg && formik.errors.subsubcategoryimg && "is-invalid"}`}
                      />
                      <ErrorMessage name="subsubcategoryimg" className="error" component="div" />
                      <br />

                      <label>Description</label>
                      <JoditEditor
                        ref={editor}
                        value={formik.values.description}
                        tabIndex={1}
                        onBlur={(newContent) => formik.setFieldValue("description", newContent)}
                        onChange={(newContent) => formik.setFieldValue("description", newContent)}
                      />
                      <ErrorMessage name="description" className="error" component="div" />
                      <br />

                      <div className="mb-3">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isFeatured"
                            checked={formik.values.isFeatured}
                            onChange={() => formik.setFieldValue("isFeatured", !formik.values.isFeatured)}
                          />
                          <label className="form-check-label" htmlFor="isFeatured">
                            Featured Product
                          </label>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isTopProduct"
                            checked={formik.values.isTopProduct}
                            onChange={() => formik.setFieldValue("isTopProduct", !formik.values.isTopProduct)}
                          />
                          <label className="form-check-label" htmlFor="isTopProduct">
                            Top Product
                          </label>
                        </div>
                      </div>

                      <input type="submit" className="btn mt-2 rounded-3 w-20 btn-lg btn-outline-secondary btn-dark" value="Submit" />
                    </Form>
                  )}
                </Formik>
              </div>
              {/* formik form */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddSubSubcategoryform;

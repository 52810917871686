import React, { useEffect, useState } from "react";
import EstPartnerForm from "./EsteemPartnerForm";
import Footer from "../footer/Footer";
import { getEstPartners } from "../../services/api";

function EstPartner() {
  const [posts, setposts] = useState([]);

  useEffect(() => {
    async function data() {
      let dat = await getEstPartners();
      setposts(dat);
    }
    data();
  }, []);

  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center"></div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card m-b-28">
                  <div className="card-body">
                    <h4 className="mt-0 header-title mb-4">Established Partners</h4>
                    <EstPartnerForm />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title mb-4">Partner Images</h4>

                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Image 1</th>
                            <th scope="col">Image 2</th>
                            <th scope="col">Image 3</th>
                            <th scope="col">Image 4</th>
                            <th scope="col">Image 5</th>
                            <th scope="col">Image 6</th>
                            <th scope="col">Image 7</th>
                            <th scope="col">Image 8</th>
                            <th scope="col">Image 9</th>
                            <th scope="col">Image 10</th>
                            <th scope="col">Image 11</th>
                            <th scope="col">Image 12</th>
                          </tr>
                        </thead>
                        <tbody>
                          {posts &&
                            posts.map((i) => (
                              <tr key={i._id}>
                                {[...Array(12)].map((_, index) => (
                                  <td key={index}>
                                    <img
                                      alt={`Partner ${index + 1}`}
                                      src={i[`img${index + 1}`]}
                                      height="80px"
                                      width="80px"
                                      className="img-thumbnail"
                                    />
                                  </td>
                                ))}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default EstPartner;
